.baseH4{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    margin-top: -3px;
    text-align:center;
    margin-left: -20px;
    font-size: 117%;
}

.especialH4{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    margin-top: -3px;
    text-align:center;
    font-size: 105%;
}

.cardContent{
    display: block;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0px auto;
    text-align:center;
}

.baseH4Comercial{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    margin-top: -3px;
    text-align:center;
    margin-left: -30px;
    font-size: 0.84rem;
}

.cardContentComercial{
    display: block;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: -10px auto;
    text-align:center;
}
