.slider-root-user {
    width: 80%;
    margin: 0px auto 10px;
    h3 {
        padding: 0;
        margin: 0;
    }
}

.error-message {
    color: #f52b2b;
    display: flex;
    gap: 4px;
    margin-top: 4px;
    align-items: center;
    font-weight: 600;
    p {
        font-size: 0.8rem;
    }
}