.rootGenerateBills {
    margin-top: 60px;
}

.container-generate-bills {
    width: 100%;
    min-height: 40px;
    border-radius: 10px 10px 0px 0px;

    .header-document-new {
        display: grid;
        grid-template-columns: 65% 35%;
        width: 100%;
        margin: 20px auto;
        gap: 10px;
        .input-elements-left {
            border-radius: 8px;
            background: #FFF;
            box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        }
    }

    .header-item-two {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 10px;
        width: 93%;
        margin: 20px auto;
        div {
            margin-bottom: 7px;
        }
    }

    .header-item-three {
        display: grid;
        grid-template-columns: auto auto auto;
        column-gap: 10px;
        width: 93%;
        margin: 0px auto;
        div {
            margin-bottom: 7px;
        }
    }

    p {
        margin: 0;
        margin-bottom: 5px;
    }

    .header-history-client-details {
        .giro-details{
            display: grid;
            grid-template-columns: 25% 75%;
            justify-content: center;
            border-radius: 8px;
            margin: 30px 0 30px;
            background: #FFF;
            align-items: center;
            box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
            height: 90px;
        }
        .details-client {
            border-radius: 8px;
            align-items: center;
            background: #FFF;
            box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        }
        .list-details {
            display: flex;
            justify-content: space-between;
            width: 90%;
            margin: auto;
            align-items: center;
            height: 60px;
        }
        .details-notes {
            margin-top: 30px;
            border-radius: 8px;
            background: #FFF;
            box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
            h4 {
                margin-top: 7px;
                margin-bottom: 5px;
            }
        }
        .notes-content {
            min-height: 150px;
        }
        h4 {
            font-family: 'Poppins', sans-serif;
            color: $btn-color-text1;
            text-indent: 10px;
            font-size: 1.2rem;
            margin: 0;
        }
        h3 {
            color: black;
            margin: 0;
        }
        p{
            font-size: 1.1rem;
        }
        span {
            font-family: 'Poppins', sans-serif;
            color: $btn-color-text1;
        }
    }
}

.cards-select-info {
    .MuiPaper-root {
        width: 540px;
    }
    .header-card {
        width: 100%;
        border-bottom: 1px solid $shadow-line1;
        min-height: 60px;
    }
    .header-card-content {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
    }
    h3 {
        font-size: 1rem;
        color: $btn-color-text1;
    }
    .radio-action-card {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $shadow-line2;
        p {
            font-weight: revert-layer;
        }
    }
    .radio-action-card:hover {
        cursor: pointer;
    }

    .content-actions {
        width: 95%;
        margin: 20px auto 20px;
      
        .container {
                width:95%; 
                margin:20px auto 20px;
        }
        .btn-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .itemData {
        display: grid;
        grid-template-columns: 20% 70% 10%;
        align-items: center;
        min-height: 50px;
        margin-bottom: 20px;
        width: 100%;
        margin: 10px auto;
        border-bottom: 1px solid $shadow-line2;
        .subtitleItem {
            font-family: 'Poppins', sans-serif;
            font-size: 0.7rem;
            font-weight: 520;
            opacity: 0.8;
        }
        .p1Item{
            text-transform: capitalize; 
            font-size: 70%;
        }
        .titleItem {
            margin-top: 10px;
        }
    }
    
}


.table-new-bill {
    width: 100%;
    h3 {
        font-size: 1.3rem;
        color: $btn-color-text1;
        font-weight:bolder;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 10px;
    }
    img {
        width: 20px;
    }
}

.details-bills-table {
    display: flex;
    justify-content: center;
    gap:1.9rem;
    h2 {
        font-size: 1.3rem;
        color: $btn-color-text1;
        font-weight: lighter;
        font-family: 'Poppins', sans-serif;
        margin: 0;
        margin-bottom: 10px;
    }
}
