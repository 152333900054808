.gridContainer{
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 4%;
    row-gap: 2em;
    width: 95%;
    margin: 20px auto;
}

.gridContainerComercial{
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 4%;
    row-gap: 2em;
    margin: auto;
}

.app-bar-header {
    border-radius: 0px 0px 0px 32px;
    border-bottom: 1.5px solid $shadow-line1;
    border-left: 1.5px solid $shadow-line1;
    background: $white-color;
    width: 100%;
    margin: 0px auto 0px;

    .app-bar-content {
        display: flex;
        justify-content: space-between;
        width: 95%;
        margin: auto;
        min-height: 70px;
        vertical-align: center;
        align-items: center;
    }
    .details-environment {
        font-family:  'Poppins', sans-serif;
        font-weight: bold;
        color:#f01616;
        font-size: 0.8rem;
        text-align: center;
        h2 {
            margin: 0;
        }
    }

}
.version-app-details {
    margin-left: 40px;
    font-size: 0.8rem;
    font-weight: lighter;
}

.icon-btn {
    border-radius: 40px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    img {
        width: 20px;
    }
}

.icon-btn:hover {
    background-color: #d4d4d4;
    cursor: pointer;
}

@media (max-width:850px) {
    .gridContainer {
        grid-template-columns: auto auto;
    }
    .gridContainerComercial{
        grid-template-columns: auto auto auto;
        column-gap: 2%;
        row-gap: 1em;
    }
}

@media (max-width:650px) {
    .gridContainerComercial{
        grid-template-columns: auto auto;
        column-gap: 2%;
        row-gap: 0.8rem;
    }
}

@media (max-width:415px) {
    .gridContainerComercial{
        grid-template-columns: auto;
        column-gap: 2%;
        row-gap: 0.8rem;
    }
}