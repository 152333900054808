@import url("https://fonts.googleapis.com/css?family=Tangerine");

.rootLogin{
    background-color: #FFF;
    .logo-login {
        display: flex;
        justify-content: center;
        background-color: #FFF;
        width: 200px;
        height: 200px;
        margin: 170px auto 0;
        border-radius: 10px;
        img {
            display: block;
            margin: 0 auto;
            padding: 30px;
            background-color: #FAFAFA;
            box-shadow: 0px 0px 20px 10px rgba(235, 235, 235, 0.75);
            width: 120px;
            height: 120px;
            aspect-ratio: 1/1;
            border-radius: 10px;
        }
    }
    .content-form-login {
        input {
            min-width: 450px;
        }
        form {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            margin-top: 60px;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40%;
        margin: -80px auto;
        box-shadow: 0px 0px 20px 10px rgba(235, 235, 235, 0.75);
        border-radius: 8px;
        text-align: left;
        p { 
            margin: 0;
            padding: 0;
            font-weight: 500;
        }
        h3 { 
            color: #373737;
        }
    }
}

@media ( 1300px < width <= 1550px ) {
    .rootLogin{
        .content-form-login {
            input {
                min-width: 400px;
            }
            width: 45%;
        }
    }
}

@media ( 1000px < width <= 1300px ) {
    .rootLogin{
        .content-form-login {
            input {
                min-width: 380px;
            }
            width: 55%;
        }
    }
}

@media ( 800px < width <= 1000px ) {
    .rootLogin{
        .content-form-login {
            input {
                min-width: 380px;
            }
            width: 65%;
        }
    }
}

@media ( 670px < width <= 800px ) {
    .rootLogin{
        .content-form-login {
            input {
                min-width: 380px;
            }
            width: 75%;
        }
    }
}

@media ( width <= 670px ) {
    .rootLogin{
        .content-form-login {
            input {
                min-width: 240px;
            }
            width: 93%;
        }
    }
}