.tabs-element-root {
    height: 34px;
    padding: 0;
    bottom: 0;
    border-radius: 10px;
    width: 100%;
    .tabs-item {
        text-transform: capitalize;
        font-family: 'Poppins' sans-serif;
    }
}