.client-information-root {
    width: 80%;
    margin: 0px auto 30px;
    .search-text {
        display: flex;
        gap: 10px;
        margin-bottom: 15px;
        width: 100%;
    }
}

.details-client-page{
    display: grid;
    width: 95%;
    margin: auto;
    grid-template-columns: 25% 75%;
    gap: 2%;
    .client-information-base {
        min-height: 650px;
        height: 100%;
        max-height: 680px;
        border: 1px solid #c7c7c7;
        box-shadow: 2px 2px 13px 1px #d3d3d3;        
        text-align: center;
        border-radius: 10px;
        h3,h4{
            padding: 0;
            margin: 0;
        }
        p {
            text-transform: capitalize;
            font-size: 0.8rem;
            margin: 0;
            padding:0;
        }
        span {
            font-weight: bold;
            font-size: 0.8rem;
        }
        .details {
            display: grid;
            width: 93%;
            font-family: 'Poppins', sans-serif;
            grid-template-columns: 49% 61%;
            margin-top: 20px;
            text-align: left;
            row-gap: 10px;
        }
    }
    .icon-client {
        font-size: 6.4rem;
    }
    .client-details-record{
        margin-top: -40px;
        p{
            padding: 0;
            font-size: 0.84rem;
            margin: 0;
        }
    }
    .address-list {
        height: 100px;
        display: grid;
        grid-template-columns: 49% 49%;
        gap: 2%;
        overflow-y: scroll;
        .direction-details {
            border-radius: 10px;
            height: 40px;
            margin-bottom: 6px;
            border: 1px solid #c7c7c7;
            div {
                width: 98%;
                margin: auto; 
            }
        }
    }
}

.document-item {
    background-color: #f8f8f8;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
    margin-bottom: 20px;
    box-shadow: 5px 5px 10px 0px #e6e6e6;
    
    .content-item {
        display: flex;
        justify-content: space-between;
        width: 95%;
        margin: auto;
    }
    .content-grid {
        display: grid;
        grid-template-columns:  55% 18% 20%;
    }
    .left-action-btn {
        display: flex;
        gap: 4px;
        margin: auto 0;
    }
}


@media (max-width: 1200px){
  .details-client-page{
    grid-template-columns: 25% 75%;
  }
}

@media (max-width: 670px){
  .details-client-page{
    grid-template-columns: 35% 65%;
  }
}