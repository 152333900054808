.list-bills-root { 
    // margin-top: 20px;
    width: 95%;
    margin: 20px auto 0;
    .item-bill-header {
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
        border-bottom: 1px dotted #666;
    }
    .content-actions-bills{
        display: grid;
        grid-template-columns: 90% 10%;
        align-items: center;
        justify-items: center;
        column-gap: 1rem;
    }
    .item-bill {
        text-align: center;
        margin-top: 3px;
    }
    .lbl-text {
        text-align: left;
        text-transform: capitalize;
        text-indent: 1rem;
    }
    .item-bill:hover {
        background-color: #d3d3d3;
        cursor: pointer;
        border-radius: 10px;
    }
    .lbl-price {
        text-align: right;
        margin-right: 1rem;
    }
    .details-alls-bills{
        display: grid;
        grid-template-columns: 30% auto;
        width: 95%;
        margin: auto;
        column-gap: 1rem;
        .resume-bills {
            background-color: #112A56;
            min-height: 170px;
            box-shadow: 0px 0px 10px 0px #00000040;
            border-radius: 7px;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            color: #fff;
            width: 90%;
            margin: auto;
            .content-bills {
                align-items: center;
                display: flex;
                justify-content: space-between;
                width: 90%;
                margin: auto;
                p,span {
                    padding: 0;
                    font-size: 1.2rem;
                    margin: 0;
                }
            }
            span {
                font-weight: bold;
            }
        }
        .content-item-resume {
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            column-gap: 1rem;
            .item-resume-bills {
                box-shadow: 0px 0px 10px 0px #00000040;
                border: 1px solid #112A56;
                height: 80px;
                margin: auto;
                width: 100%;
                border-radius: 5px;
                .content-item {
                    width: 90%;
                    margin: 10px auto;
                }
                p,span {
                    padding: 0;
                    font-size: 1.2rem;
                    margin: 0;
                }
                span {
                    color:#112A56;
                    font-weight: bold;
                }
            }
        }
    }
    h2 {
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
    }
}

.modal-bills {
    width: 100%;
    .header-modal {
        width: 90%;
        margin: 5px auto;
        display: flex;
        justify-content: space-between;
    }
    .content-modal {
        display: grid;
        grid-template-columns: 20% 30% 20% 30%;    
        h4 {
            font-family: 'Poppins',  sans-serif;
            padding: 0;
            margin: 0;
            margin-bottom: 30px;
            font-weight: bold;
        }
        p {
            padding: 0;
            margin: 0;
            margin-bottom: 30px;
            font-family: 'Poppins', sans-serif;
        }
    }

    .details-bills {
        display: grid;
        grid-template-columns: 50% 50%;    

    }
    .authorization-element{
        margin-bottom: 30px;
        border: 1px solid #666;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        background-color: #e6e6e6;
        p {
            padding: 0;
            margin: 0;
        }
        .content-authorization {
            width: 80%;
            margin: auto;
        }
    }
    ::-webkit-scrollbar {
        transition: all 0.3s;
        background-color: #fafafa;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #c1c1c1;
    }
}
.collapse-content{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 30% 70%;
    column-gap: 1rem;
    .other-option-collapse {
        display: flex;
        justify-content: space-between;
    }
    .action-btn-content{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        button {
            text-transform: capitalize;
        }
    }
}
