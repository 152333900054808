.search-bar {
    width: 80%;
    margin: 0 auto;
}

.items-order-list {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    width: 95%;
    margin: 0 auto 30px;
}


@media (max-width: 960px) {
    .items-order-list {
        grid-template-columns: auto auto;
        gap: 20px;
    }
    
}



@media (max-width: 500px) {
    .items-order-list {
        grid-template-columns: auto;
    }
    
}