// Botones
$btn-color-text1: #173456;
$btn-color-background1: #FFFFFF;


// Sombras


// Lineas
$shadow-line1: #EBEBEB;
$shadow-line2: #cacaca;

$hover-color: #d4d4d4;

$item-text-selected: #315783;
$item-background-selected: #e9e9eb;
$border-color-secondary: #30B595;
$border-bottom-color: #afafaf;


// Colors base
$white-color: #FFF;