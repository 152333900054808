.react-calendar {
    width: 400px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar__navigation button {
    color: #112a56;
    min-width: 44px;
    background: none;
    font-size: 16px;
    text-transform: capitalize;
    border: none;
    margin: 8px auto;
    font-weight: bold;
}

.react-calendar__navigation{
    display: flex;
    border: none;
    height: 60px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}

abbr[title] {
    text-decoration: none;
    text-transform: capitalize;
    justify-content: center;
    font-weight: bold;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #112a56;
    border-radius: 6px;
}

.react-calendar__tile--now {
    background: #112a5673;
    border-radius: 6px;
    font-weight: bold;
    color: #112a56;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    color: #112a56;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;

}


.react-calendar__tile {
    height: 40px;
    font-family: 'Poppins', sans-serif;
    color: #112a56;
    border : none;
}

.react-calendar__tile--active {
    background: #112a56;
    border-radius: 6px;
    font-weight: bold;
    color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #112a56;
    color: white;
    border-radius: 0;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
    color:#112a56;
}

.react-calendar__tile--range {
    background: #b7cff8;
    color: #112a56;
    border: none;
    border-radius: 0px;
}

.react-calendar__tile--rangeStart {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #112a56;
    color: white;
}

.react-calendar__tile--rangeEnd {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #112a56;
    color: white;
}