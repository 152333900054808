.root-detail-bill {
    background-color: #102133;
    height: 100vh;
    
    .appbar-detail-bill {
        color:#FFFFFF;
    }
    h1 {
        margin-top: 0;
        text-indent: 70px;
        font-weight: 480;
        letter-spacing: 3px;
    }
    .container-page-bill {
        display: flex;
        width: 95%;
        margin: auto ;
        overflow-x: scroll;
    }
}


/*PAGINA DE FACTURA*/
.root-page-bill {
    width: 300px;
    min-height: 75vh;
    margin-left: 10px;
    margin-right: 20px;
    background-color: #FFFFFF;
    margin-bottom: 40px;
    margin-top: -7px;
    box-shadow: 3px 4px 7px 1px rgba(255,255,255,0.4);
    border: 0.1px solid #9c9c9c;
    .header-page-bill{
        width: 100%;
        color:white;
        height: 115px;
    }
    .page-subtitle-bill {
        margin-top: -15px;
        font-size: 13px;
    }
    
    .body-page-bill {
        margin: 0 10px 0 10px;
        .item-bill {
            min-width: 100%;
            display: flex;
            height: 40px;
            margin-top: 12px;
            border-bottom: 1px solid #8c8f8d;
        }
    }

}

