@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap");

@import "./colors.scss";
@import "./colors.scss";

@import "./Pages/Index.scss";
@import "./Pages/Login.scss";
@import "./Pages/Bills.scss";
@import "./Pages/Abouts.scss";


@import "./Pages/GenerateBills.scss";
@import "./Pages/Comercial.scss";
@import "./Pages/ReportActivity.scss";
@import "./Pages/InformationRequest.scss";
@import "./Pages/TechnicalSupport.scss";

@import "./Components/TabsElements.scss";
@import "./Components/Cards.scss";
@import "./Components/Calendar.scss";
@import "./Components/DetailBill.scss";
@import "./Components//UserControl.scss";

.cards-root {
    .card-home {
        height: 19vh;
        img {
            display: block;
            margin: auto;
            aspect-ratio: 1/1;            
            width: 130px;
        }
    }
    .card-comercial {
        img {
            display: block;
            margin: auto;
            margin-top: 20px;
            aspect-ratio: 1/1;            
            width: 90px;
        }
    }
    .card-base {
        min-height: 170px;
        box-shadow: 3px 1px 16px 3px #ededed;
        border-radius: 8px;
        width: 100%;
    }
}

h1,h3,h5 {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
}

h4 {
    font-family: "Poppins", sans-serif;
    font-weight: lighter;
}

p,span {
    font-family: "Poppins", sans-serif;
}

/*Para los errores*/
.errorContainer {
    display: flex;
    transition: all 0.4s;
    color: red;
    margin-top: 3px;
}

.errorMessage {
    text-indent: 8px;
    font-size: 12px;
    margin-top: 3px;
}

/* Contenedor base  */
.containerStyleBase {
    margin-top: 60px;
    width: 92%;
    margin: 50px auto;
}
.not-found{
    margin: 0;
    padding: 0;
    img {
        width: 19%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.loader-app {
    position: absolute;
    top: 50%;
    left: 50%;
}

@media only screen and (max-width: 800px)  {
    .not-found{
        img {
            width: 40%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.card-confirmation {
    .title-card {
        text-align: center;
        font-weight: bold;
        font-family: 'Poppins', sans-serif; 
    }
    p {
        margin: 0;
        padding: 0;
        font-weight: bold;
        text-transform: capitalize;
    }
    button {
        height: 40px;
    }
}
.mx-iframe {
    height: 400px;
}
.btn-card-print{
    display: flex;
    justify-content: center;
    gap: 1rem;
}