.container-tecnhical-supoprt {
    display: grid;
    grid-template-columns: minmax(230px, 25%) auto;
    .menu-options {
        .content-options {
            width: 90%;
            margin: auto;
            img {
                width: 30px;
                margin-left: 5px;
                height: 30px;
            }
        }
        .item-option-menu {
            display: flex;
            gap: 0.5rem;
            border: 1px solid $shadow-line1;
            border-radius: 5px;
            margin-bottom: 10px;
        }
        img {
            width: 30px;
            margin-left: 5px;
        }
        .item-option-menu:hover {
            background-color: $hover-color;
            cursor: pointer;
        }
        .item-selected {
            background-color: $item-background-selected;
            color: $item-text-selected;
            font-weight: bold;
        }
        .icon-item {
            font-size: 27px;
            margin: 14px 0 0 6px;
        }
    }
    .content-technical{
        width: 95%;
        margin: auto;
    }
}
.not-order {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        color: #112a56;
        font-weight:bold;
        font-size: 1.2rem;
    }
    img {
        opacity: 0.7;
        width: 30%;
        margin: auto;
        max-width: 300px;
    }
}

.card-order-update {
    border: 2px solid $border-color-secondary;
    margin: 0 auto;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 25px;
}
.content-user-list {
    display: grid;
    grid-template-columns: 31% 31% 31%;
    column-gap: 3%;
    .list-items-users {
        display: grid;
        grid-template-columns: 14% 69% 9%;
        align-items: center;
        justify-content: center;
        gap: 3%;
        border: 1px solid $border-bottom-color;
        height: 110px;
        border-radius: 10px;
        margin-top: 10px;
        p{
            padding: 0;
            margin: 0;
            font-size: 0.9rem;
            text-overflow: ellipsis;
            white-space: nowrap; // Evita que el texto se divida en varias líneas
            overflow: hidden;
        }
        h3 {
            padding: 0;
            margin: 0;
        }
        .content-card-details-user {
            gap: 10px;
        }
        .icon-contianer {
            background-color: #D1D2D3;
            text-align: center;
            display: flex;
            justify-content: center;
            border-radius: 6px;
        }
    }
    .ciruclar-point-base {
        width: 10px;
        height: 10px;
        // margin-top: 28px;
        border-radius: 10px;
    }
    .ciruclar-point-active {
        background-color: #36df14;
    }
    .ciruclar-point-deactive {
        background-color: #dd1c1c;
    }
}
h5 {
    padding: 0;
    margin: 10px 0;
}
.third-modal-user {
    .header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.user-details-card {
    border-bottom: 1px dotted #363636;   
    margin-bottom: 20px;
    padding-bottom: 10px;
    .two-details-confirmation-user {
        display: grid;
        grid-template-columns: 30% 70%;
        row-gap: 0.5rem;
        width: 95%;
        margin: 10px auto;
        span {
            font-weight: 600;
        }
    }

    .role-details-user {
        display: grid;
        grid-template-columns: 49% 49%;
        row-gap: 0.5rem;
        column-gap: 1%;
        .card-role {
            display: flex;
            flex-direction: column;
            border: 1px dotted #363636;
            border-radius: 8px;
            height: 80px;
            justify-content: center;
            align-items: center;
            span {
                text-indent: 10px;
            }
        }
        h3 {
            text-align: center;
        }
    }
    h4, p {
        padding: 0;
        margin: 0;
    }
}
.user-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    p, h4 {
        padding: 0;
        margin: 0;
        color: #112A56;
    }
    h4 {
        font-weight: bold;
    }
    p {
        font-size: 1.2rem !important;
    }
    .class-content {
        background-color: #f3f3f3;
        border-radius: 10px;
        .details {
            display: flex;
            padding: 20px;
            align-items: center;
            gap: 10px;
        }
    }
}

.content-benchmark {
    display: grid;
    grid-template-columns: 70% 30%;
    column-gap: 1rem;
    .contianer-list-notes {
        border-right: 1px solid #cacaca;
    }
    .container-details {
        display: grid;
        grid-template-columns: 30% 70%;
        margin-bottom: 10px;
        gap: 1rem;
       
        h3,p{
            font-size: 1rem;
            padding: 0;
            margin: 0;
        }
        
    }
    .action-dialog {
        button {
            margin: 0 auto 20px;
            min-width: 100px;
        }
    }
    .element-note-detail {
        border: 1px solid $border-bottom-color;
        width: 95%;
        margin: 0px auto 10px;
        border-radius: 10px;
        .content-element {
            display: grid;
            grid-template-columns: auto auto;
            width: 90%;
            margin: 5px auto;
        }
        h3,p {
            font-size: 1rem;
            padding: 0;
            margin: 0;
        }
        span {
            font-weight: bold;
        }
    }
}

.content-form-user {
    .forms-content {
        display: grid;
        grid-template-columns: 49.5% 49.5%;
        column-gap: 1rem;
        align-items: center;
        row-gap: 1rem;
        margin-bottom: 0.4rem;
    }
    .password-container{
        display: grid;
        grid-template-columns: 93% 7%;
        column-gap: 1rem;
        align-items: center;
        margin-bottom: 1rem;
    }
    .password-container-2 {
        margin-bottom: 1rem;
        display: block;
    }
    h3,p {
        font-size: 1rem;
        padding: 0;
        margin: 0;
    }
    .code-users {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
    }
}
.form-second-user {
    display: grid;
    grid-template-columns: 29.5% 69.5%;
    column-gap: 1rem;
    row-gap: 0.6rem;
    align-items: center;
    h4 {
        font-weight: bold;

    }
}
.form-fourt-user {
    display: grid;
    margin: 20px 0 0;
    grid-template-columns: 19% 29.5% 19% 29.5%;
    column-gap: 1.25%;
    align-items: center;

}

@media (max-width:1250px) {
    .content-user-list {
        display: grid;
        grid-template-columns: 49% 49%;
        gap: 0.3rem;
    }
}

@media (max-width:610px) {
    .content-user-list {
        display: grid;
        grid-template-columns: 100%;
        gap: 0.3rem;
        margin: 0 auto;
    }
}


@media (max-width: 850px) {
    .container-tecnhical-supoprt {
        display: block;
        .menu-options {
            .content-options {
                display: grid;
                grid-template-columns: auto auto auto;
                gap: 0.5rem;
            }
        }
    }
}

@media (max-width: 550px) {
    .container-tecnhical-supoprt {
        display: block;
        .menu-options {
            .content-options {
                display: grid;
                grid-template-columns: auto auto ;
                gap: 0.5rem;
            }
        }
    }
}