.header-about-element {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 95%;
    column-gap: 1.4rem;
    row-gap: 2rem;
    margin: auto;
    .cards {
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        min-height: 155px;
        align-items: center;
    }
    .content-card { 
        width: 90%;
        margin: auto;
    }
    .footer-card-element {
        display: flex;
        justify-content: space-between;
        min-height: 40px;
        align-items: center;
        margin-top: 10px;
        p {
            font-weight: bold;
        }
    }

    .content-element-card {
        display: flex;
        gap: 20px;
    }
    h1 {
        color: $btn-color-text1;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        margin: 0;
    }
    span {
        font-family: 'Poppins', sans-serif;
        color: $btn-color-text1;
        font-weight: bold;
    }
    p {
        margin: 0;
        font-weight: 500;
    }
}