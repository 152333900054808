.root-graphic-report {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: minmax(330px, 30%) auto;
    margin-bottom: 40px;

    h1 {
        margin: 0;
        padding: 0;
    }

    .container-detials-canals {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 1%;
        // overflow: break-word;
        // over/
    }

    .item-detials-canals {
        border-radius: 10px;
        border: 1px solid #c7c7c7;
        width: 100%;

        div {
            width: 95%;
            margin: 0 auto;
        }
    }

    .history-activity {
        display: flex;
        gap: 6px;
        text-align: center;

        .card-activity {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #c7c7c7;
            border-radius: 20px;
            width: 25%;
        }

        p {
            color: #2FC934;
            font-weight: bold;
        }
    }
}


@media (max-width: 900px) {
    .root-graphic-report {
        .container-detials-canals {
            grid-template-columns: auto auto;
        }
    }
}

@media (max-width: 750px) {
    .root-graphic-report {
        .container-detials-canals {
            grid-template-columns: auto;
        }
    }
}

@media (max-width: 500px) {
    .root-graphic-report {
        display: block;
        margin-top: 40px;
        .history-activity {
            .card-activity {
                width: 50%;
            }
        }
    }
}